<template>
  <div class="loader">
    <img src="./assets/img/logo.png" alt="Obrazek informujący o ładowaniu strony" />
  </div>
</template>

<script>
export default {
  name: 'Loader'
}
</script>

<style lang="scss" scoped>
.loader {
  img {
    max-width: 200px;
  }
}
</style>
